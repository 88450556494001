.accessionaries-list-page {
  .type {
    @extend .box-shadow;
    @extend .animated;
    position: relative;
    margin-bottom: 1rem;
    &:hover {
      transform: translateY(-5px);
    }
    .link {
      @extend .ext-absolute-expand;
      @extend .ext-gradient;
      padding: 1rem;
      color: white;
      text-transform: uppercase;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.accessionary-view-page {
  #wo-breadcrumbs{
    position: absolute;
    & *{
      color: white !important;
      text-shadow: 0 0 3px black;
    }
  }
  .bg{
    background-size: cover;
    background-position: center center;
    min-height: 250px;
    .bg-i{
      @extend .ext-gradient;
      padding-top: 3rem;
      &, & .breadcrumbs li, a {
        color: white !important;
        text-shadow: 0 0 3px black;
      }
    }
    .description{
      text-shadow: 0 0 5px black;
    }
  }
  .quick-links {
    @extend .ext-quick-links;
  }
  #products-filters{
    position: relative;
  }
  .filter-row{
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5rem;
    @include device-size('mobile'){
      flex-direction: column;
      .filter-item{
        margin-bottom: 0.4rem;
      }
    }
    .filter-label {
      margin-right: 0.7rem;
      label {
        font-weight: bold;
      }
    }
    .item {
      display: inline-block;
      margin-right: 1rem;
      label,
      .cb {
        display: inline-block;
      }
    }
  }
  #items-list {
    .item{
      @extend .box-shadow;
      @extend .animated;
      position: relative;
      overflow: hidden;
      margin-bottom: 1rem;
      &:hover {
        transform: translateY(-5px);
      }
      .title {
        @extend .ext-absolute-expand;
        @extend .ext-gradient;
        text-transform: uppercase;
        a {
          @extend .ext-absolute-expand;
          color: white;
          padding: 1rem;
        }
      }
    }
  }
}
.article-page {
  .thumb {
    @extend .box-shadow;
    margin-bottom: 30px;
  }
}
.articles-list {
  .article-item-wrapper {
    @extend .box-shadow;
    margin-bottom: 30px;
  }
  .article-item {
    position: relative;
    overflow: hidden;
    .text-wrapper,
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .overlay {
      background: rgba(0, 0, 0, 0.5);
      transition: all 300ms ease-in;
    }
    &:hover {
      .overlay {
        background: rgba(0, 0, 0, 0.75);
      }
    }
    .text-wrapper {
      @extend .font-heading;
      color: white;
      padding: 15px;
    }
  }
}

.contacts-page {
  #page-content{
    form{
      margin-bottom: 15px;
    }
    .text{
      margin-top: 25px;
      margin-bottom: 25px;
      position: relative;
      overflow: hidden;
    }
  }
}
.home-page {
  .sy-controls{
    li{
      &:before{
        background: none !important;
        display: none;
      }
    }
  }
  .slippry_box {
    box-shadow: 0 5px 2px -2px rgba(135, 127, 127, .7);
    max-height: 570px;
    overflow: hidden;
  }
  .slider-thumbs {
    display: flex;
    justify-content: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    a{
      display: inline-block;

      margin: 5px;
      img {
        @extend .box-shadow;
        width: 18px;
        height: 18px;
        border-radius: 50%;
      }
    }
  }
  .stock {
    @extend .box-shadow;
    @extend .animated;
    position: relative;
    margin-bottom: 1rem;
    &:hover {
      transform: translateY(-5px);
    }
    .title {
      @extend .ext-absolute-expand;
      @extend .ext-gradient;
      color: white;
      padding: 1rem;
      text-transform: uppercase;

    }
  }
  .article {
    margin-bottom: 1.5rem;
    .title{
      a{
        color: $color-red;
        text-decoration: underline;
        &:hover{
          text-decoration: none;
        }
      }
    }
    .created {
      @extend .ext-created;
    }
  }
  .review {
    margin-bottom: 1rem;
    .created {
      @extend .ext-created;
    }
  }
}

.kitchens-category-view-page {
  .breadcrumbs {
    display: none;
  }
  .bg .breadcrumbs {
    display: block;
  }
  .bg {
    background-size: cover;
    background-position: center center;
    min-height: 250px;
    .bg-i {
      @extend .ext-gradient;
      &, & .breadcrumbs li, a {
        color: white !important;
        text-shadow: 0 0 3px black;
      }
    }
  }
  .quick-links {
    @extend .ext-quick-links;
  }
  .filter-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5rem;
    @include device-size('mobile'){
      flex-direction: column;
      .filter-item{
        margin-bottom: 0.4rem;
      }
    }
    .filter-label {
      margin-right: 0.7rem;
      label {
        font-weight: bold;
      }
    }
  }

  // @TODO REFACTOR
  .filter-item {
    display: inline-block;
    margin-right: 1rem;
    label,
    .cb {
      display: inline-block;
    }
  }
  #kitchen-filters {
    position: relative;
    label{
      cursor: pointer;
    }
  }

  // @TODO EOF REFACTOR
  .kitchen {
    @extend .box-shadow;
    @extend .animated;
    position: relative;
    overflow: hidden;
    margin-top: 1rem;
    margin-bottom: 1rem;
    &:hover {
      transform: translateY(-5px);
    }
    .title {
      @extend .ext-absolute-expand;
      @extend .ext-gradient;
      text-transform: uppercase;
      a {
        @extend .ext-absolute-expand;
        color: white;
        padding: 1rem;
      }
    }
  }
}
.kitchens-list-page {
  .type {
    @extend .box-shadow;
    @extend .animated;
    position: relative;
    margin-bottom: 1rem;
    &:hover {
      transform: translateY(-5px);
    }
    .link {
      @extend .ext-absolute-expand;
      @extend .ext-gradient;
      padding: 1rem;
      color: white;
      text-transform: uppercase;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.popup-kitchen-view-page,
.popup-accessionary-page{
  min-width: 1000px;
  @include device-size('mobile'){
    min-width: 100%;
    padding: 15px !important;
    margin: 0 !important;
  }
}
.reviews-list-page {
  .review {
    margin-bottom: 1rem;
    .created {
      @extend .ext-created;
    }
  }
}
.static-page {
  .page-content {
  }
}
.works-list-page {
  .item{
    margin-bottom: 1.5rem;
  }
  .work-example {
    display: flex;
    margin-bottom: 1rem;
    .primary-img {
      margin-right: 1rem;
      flex-basis: 30%;
      img {
        @extend .box-shadow;
        padding: 2px;
      }
      @include device-size('mobile'){
        justify-content: center;
        margin-bottom: 1rem;
        margin-right: 0;
      }
    }
    .secondary-images {

      img {
        @extend .box-shadow;
        padding: 2px;
        margin: 0 10px 15px 0;
      }
    }
    .info {
      flex-direction: column;
      display: flex;
      flex-basis: 70%;
      .secondary-images {
        flex-direction: row;
        display: flex;
        flex-wrap: wrap;
        @include device-size('mobile'){
          justify-content: center;
        }
      }
    }
    @include device-size('mobile'){
      flex-direction: column;
    }
  }
}
#admin-panel {
  font-family: sans-serif;
  background: rgb(34, 34, 34);
  padding: 0;
  color: white;
  ul {
    background-color: transparent;
    li {
      margin-right: 10px;
      border-right: solid 1px darken(rgba(34, 34, 34, 0.3), 10);
      padding: .6rem 0.5rem;
      line-height: 1;
      &:last-child{
        border-right: none;
      }
      * {
        @include device-size('mobile') {
          font-size: 11px;
          text-transform: uppercase;
        }
      }
      i {
        @include device-size('mobile') {
          display: none;
        }
      }
    }
  }
  a {
    font-size: 13px;
    color: white;
    padding: 0;
    &:hover {
      color: #ffbe0a;
    }
  }
}

#wo-breadcrumbs {
  background: none;
  padding-left: 0;
  padding-top: 1rem;
  color: $color-link-primary;
  a {
    color: $color-link-primary;
  }
}

.button{
  &.secondary{
    background-color: #9e9e9e;
  }
  &.success{
    background-color: yellowgreen;
    &:hover{
      background-color: darken(yellowgreen, 10);
    }
  }
}
form {
  @extend .font-heading;

  label {
    font-weight: 100;
  }

  .btn-action {
    color: $color-text;
    background-color: $color-bg-action-button;
    border-color: $color-bg-action-button - 20;
    transition: all 300ms ease-in;
    &:hover {
      color: $color-text  - 20;
      background-color: $color-bg-action-button-hover;
    }
  }
  .button {
    background: #96c0ce;
    &:hover {
      background: darken(#96c0ce, 10);
    }
  }
}

.callout {
  &.secondary {
    @extend .box-shadow;
    background-color: #74828f;
    &,
    & label {
      color: #ffffff;
    }
  }
}
body.admin {
  .info-block {
    position: relative;
    overflow: hidden;
    &:hover {
      filter: grayscale(30%);
      opacity: 0.8;
      outline: dashed 1px rgba(123, 123, 123, 0.87);
      & .edit-wrapper {
        opacity: 1;
      }
    }
    .edit-wrapper {
      opacity: 0;
      position: absolute;
      top: 3px;
      right: 3px;
      justify-content: center;
      display: flex;
      align-items: center;
      transition: all 0.1s ease-in;
      a {
        color: #333 !important;
        font-weight: bold;
        font-size: 14px;
        transition: all 0.2s ease-in;
        &:hover {
          color: #666 !important;
        }
      }
    }
    .edit-link {
      display: inline-block;
      text-align: center;
      border-radius: 50%;
      font-size: 24px;
      color: #333;
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.alert {
  animation: fadein 2s;
  padding: 5px 10px;
  margin: 0;
  &:empty{
    display: none;
  }
  ul {
    padding: 0;
  }
  li {
    list-style: none;
  }
}

.alert-warning {
  background-color: #FFFAB2;
  border-color: #FFE075;
  color: #DC7C00;
}

.alert-success {
  background-color: #DEFBA7;
  border-color: #C8F67E;
  color: #5B8721;
}

.alert-danger {
  background-color: #FFD9D9;
  border-color: #FFBFBF;
  color: #D00;
}

.text-danger {
  color: #D00;
}
#mobile-navigation {
  @extend .box-shadow;
  display: inline-block;
  text-align: center;
  width: 100%;
  background: $nav-panel-bg-color;
  padding-top: 0.6rem;
  .button {
    background: $color-red;
  }
  .site-name {
    font-weight: bold;
    font-size: 1.4rem;
    a {

      color: white;
    }
  }
  .site-name,
  .slogan {
    @extend .font-heading;
    color: white;
    margin-bottom: 0.5rem;
  }
}
.show-modal-btn {
  cursor: pointer;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  @extend .box-shadow;
  @extend .animated;
  width: 450px;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px 30px;
  position: relative;
  @include device-size('tablet') {
    width: 75%;
  }
  @include device-size('mobile') {
    width: 90%;
    padding: 10px;
  }
}

.modal-header h4 {
  margin-top: 0;
  color: #525564;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}
#my-info {
  @include device-size('mobile') {
    .logo,
    .slogan {
      display: none;
    }
    .info-block {
      p {
        margin-bottom: 5px;
      }
    }
  }
  @include device-size('tablet') {
    .logo,
    .slogan {
      display: none;
    }
    .info-block {
      p {
        margin-bottom: 5px;
      }
    }
  }
}
.pagination{
  .current{
    background-color: $color-red;
  }
}
.preloader {
  position: absolute;
  background: rgba(70, 64, 64, 0.24);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center
}

$scroll-top-size: 34px;
$scroll-top-margin: 15px;

#scroll-to-top {
  position: fixed;
  bottom: $scroll-top-margin;
  right: $scroll-top-margin;
  background: $color-red;
  width: $scroll-top-size;
  height: $scroll-top-size;
  line-height: $scroll-top-size;
  text-align: center;
  cursor: pointer;
  color: white;
  &:hover{
    background: darken($color-red, 10);
  }
}

@import "abstract/variables";
@import "abstract/_mixins.device-size.scss";

$gradient-start-opacity: 0.6;
$gradient-end-opacity: 0.01;

.common-font {
  font-family: 'Roboto', sans-serif !important;
  font-weight: 300;
}

.font-heading {
  @extend .common-font;
}

.ext-created {
  font-size: 0.7rem;
  color: rgb(111, 111, 111);
}

.animated {
  transition: all 0.3s ease-in;
}

.ext-absolute-expand {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.box-shadow {
  border: 1px solid rgba(135, 127, 127, .45);
  box-shadow: 0 5px 9px rgba(135, 127, 127, .7);
  transition: all 200ms ease-in;
  &:hover {
    box-shadow: 0 0 6px rgba(135, 127, 127, .95);
  }
}

.ext-gradient {
  background: -moz-linear-gradient(top, rgba(0, 0, 0, $gradient-start-opacity) 0%, rgba(0, 0, 0, $gradient-end-opacity) 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, $gradient-start-opacity) 0%, rgba(0, 0, 0, $gradient-end-opacity) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, $gradient-start-opacity) 0%, rgba(0, 0, 0, $gradient-end-opacity) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6000000', endColorstr='#00000000', GradientType=0);
}

.ext-gradient-reverse {
  background: -moz-linear-gradient(top, rgba(0, 0, 0, $gradient-end-opacity) 0%, rgba(0, 0, 0, $gradient-start-opacity) 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, $gradient-end-opacity) 0%, rgba(0, 0, 0, $gradient-start-opacity) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, $gradient-end-opacity) 0%, rgba(0, 0, 0, $gradient-start-opacity) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6000000', endColorstr='#00000000', GradientType=0);
}

.ext-quick-links {
  padding-top: 1rem;
  @include device-size('mobile') {
    .button-group {
      flex-direction: column;
    }
  }
  .button {
    background-color: $color-red;
    color: white;
    @include device-size('mobile') {
      display: block !important;
      width: 100% !important;
    }
    @include device-size('tablet') {
      justify-content: center;
      display: flex !important;
      align-items: center;
    }
    &.secondary {
      background-color: #beb9b5;
      color: black;
      &:hover {
        background-color: $color-red;
        color: white;
      }
    }
  }
}
@import "breakpoint";

$mobile-breakpoint: 0 640px;
$tablet-only-breakpoint: 641px 1024px;
$tablet-breakpoint: 0 1024px;
$desktop-breakpoint: 1025px;
$large-desktop-breakpoint: 1200px;

// Tell Breakpoint to convert all px values to em
@include breakpoint-set('to ems', true);

@mixin device-size($media) {
  @if( $media == 'mobile' ) {
    @include breakpoint($mobile-breakpoint) {
      @content;
    }
  }
  @else if $media == 'tablet-only' {
    @include breakpoint($tablet-only-breakpoint) {
      @content;
    }
  }
  @else if $media == 'tablet' {
    @include breakpoint($tablet-breakpoint) {
      @content;
    }
  }
  @else if $media == 'desktop' {
    @include breakpoint($desktop-breakpoint) {
      @content;
    }
  }
  @else if $media == 'large-desktop' {
    @include breakpoint($large-desktop-breakpoint) {
      @content;
    }
  }
  @else {
    @warn "'#{$media}' is not a supported value for 'device-size'.";
  }
}
@mixin font-family-heading(){
  font-family: 'Merriweather', serif !important;
}
//$base-color: #7c1085;
//$base-color-light: #a515b2;
//$base-color-strong: #4e0a54;

$base-color: #4A4845;
$base-color-light: #63605a;
$base-color-strong: #30302d;

$color-link-primary: $base-color-strong;
//$color-bg-action-button: $base-color-strong;
$color-bg-action-button: #e6e6e6;
//$color-bg-action-button-hover: $base-color-light;
$color-bg-action-button-hover: $color-bg-action-button - 30;

$nav-panel-bg-color: #2a2b2f;

$color-text: #4A4845;

$color-red: #c25b56;
$footer-color-links: lighten($color-red, 10);